import React, { useEffect } from 'react';
import GridLayout from '@mui/material/Grid';
import { StaticImage } from 'gatsby-plugin-image';
import { SlowReveal } from '../utility/Animations';
import { Helmet } from 'react-helmet';

const Video = (props) => {
	useEffect(() => {
		window.addEventListener('scroll', SlowReveal);
	}, []);

	return (
		<>
			<Helmet>
				<script
					src='https://fast.wistia.com/assets/external/E-v1.js'
					async></script>
			</Helmet>

			<section className={props.class} id={props.id}>
				<GridLayout container spacing={2}>
					<GridLayout item xs={12} md={4}>
						<div className='videoCopy'>
							<h2>{props.header}</h2>
							<p className='videoHeader'>{props.copy}</p>
						</div>
					</GridLayout>

					<GridLayout item xs={12} md={8}>
						<div className='indexVideo videoScreenshot'>
							{/* <StaticImage
								src='../../../static/images/JPWS_Home_Video_Thumbnail.png'
								placeholder='blur'
								alt='video place holder'
							/> */}
							<div className='wistia_responsive_padding'>
								<div className='wistia_responsive_wrapper'>
									<iframe
										src='https://fast.wistia.net/embed/iframe/7f5c9r1sy2?videoFoam=true'
										title='Webinars Teaser Video'
										allow='autoplay; fullscreen'
										allowtransparency='true'
										frameborder='0'
										scrolling='no'
										class='wistia_embed'
										name='wistia_embed'
										msallowfullscreen
										width='100%'
										height='100%'></iframe>
									{/* <iframe src="https://fast.wistia.net/embed/iframe/7f5c9r1sy2" title="Webinars Teaser Video" allow="autoplay; fullscreen" allowtransparency="true" frameborder="0" scrolling="no" class="wistia_embed" name="wistia_embed" msallowfullscreen width="100%" height="100%"></iframe> */}
									{/* <div className='wistia_embed wistia_async_7f5c9r1sy2 videoFoam=true'>
										<div className='wistia_swatch'>
											<img
												className='wistia_swatch_img'
												src='https://fast.wistia.com/embed/medias/7f5c9r1sy2/swatch'
												alt='Home Page Main Video Still image before video play'
												aria-hidden='true'
												onload='this.parentNode.style.opacity=1;'
											/>
										</div>
									</div> */}
								</div>
							</div>
						</div>
					</GridLayout>
				</GridLayout>
				<GridLayout item xs={12} md={12}>
					<div className='indexQuote'>
						<img src='/images/icon-quote.svg' className='topQuote' alt='' />
						<div className='reveal'>
							<p>{props.quote}</p>
							<p className='quoteName'>{props.quoteName}</p>
						</div>
					</div>
				</GridLayout>
			</section>
		</>
	);
};

export default Video;
