import React from 'react';
import Hero from '../components/structure/Hero';
import Video from '../components/structure/Video';
import Layout from '@components/structure/Layout';
import Seo from '@components/utility/Seo';
import { StaticImage } from 'gatsby-plugin-image';
import { isSafari } from "react-device-detect";

const Homepage = () => {
	return (
		<Layout pageType='landing'>
			<Seo
				title='Narcolepsy and Idiopathic Hypersomnia | Jazz Webinars'
				description='Learn about narcolepsy and idiopathic hypersomnia from the experts. Jazz Pharmaceuticals brings you the latest information through live, interactive webinars and on-demand videos delivered to you by experts in sleep medicine.'
			/>

			<div className='heroBackground'>
				<div className='heroInner'>
					<StaticImage
						src='../../static/images/landingPageHero.jpg'
						alt='Home Page Learn More Hero Image Desktop Size'
						width={515} 
						height={411}
						layout='fullWidth'
						placeholder='blurred'
						className='lp-image desktopOnly'
					/>
					<StaticImage
						src='../../static/images/landingPageHero-mobile.jpg'
						alt='Home Page Learn More Hero Image Mobile Size'
						layout='fullWidth'
						placeholder='blurred'
						className='lp-image mobileOnly'
					/>
					<div className='copy'>
						{/* <span className='yellowSquare'></span>
						<span className='learnCopy'>LEARN</span> */}
						{isSafari ? <img src="/images/learnmore-static.png"  className='svg static'/> : <object type="image/svg+xml" data="/images/learnmore.svg" className='svg'></object>}

						<p className='fade-in'>
							about <b>Narcolepsy</b> <br className='mobileOnly' />
							and <br className='desktopOnly' />
							<b>Idiopathic Hypersomnia (IH)</b> from experts
						</p>
					</div>
				</div>
			</div>
			<div className='subHero'>
				<div className='subHeroInner'>
					<p className='copy'>
						If you're living with or caring for someone with Narcolepsy or
						Idiopathic Hypersomnia, access the latest information through
						free, live and on-demand webinars presented by experts on sleep
						medicine.
					</p>
				</div>
			</div>

			<Hero
				headerOne='To get started, select the topic you’re interested in:'
				cardOne={{
					header: 'Narcolepsy',
					bodyBold:
						'Managing narcolepsy from lifestyle to treatment requires care and dedication.',
					bodyRegular:
						'Learn about different aspects of this condition through live webinars with experts of sleep medicine.',
					innerCardOne: {
						header: 'Symptoms and Impact',
						bodyRegular:
							'No matter where you are in your journey with narcolepsy, discover some of the ways that it can impact your day-to-day life.',
						url: {
							branded: '/narcolepsy',
							unbranded: '/idiopathic-hypersomnia',
						},
					},
					innerCardTwo: {
						header: 'Treatment Options',
						bodyRegular:
							'Learn more about options for treating key symptoms of narcolepsy to help inform discussions with your doctor.',
						url: {
							branded: '/xywav-narcolepsy',
							unbranded: '/xywav-idiopathic-hypersomnia',
						},
					},
				}}
				cardTwo={{
					header: 'Idiopathic Hypersomnia ',
					bodyBold: 'Coming across information on Idiopathic Hypersomnia can be  difficult. ',
					bodyRegular: "We're here to help! You can access live, interactive webinars with healthcare professionals right here. ",
					innerCardOne: {
						header: 'Treatment Options',
						bodyRegular:
							'Find out more about treating Idiopathic Hypersomnia, so you can be better equipped to speak with your doctor.',
						url: {
							branded: '/xywav-narcolepsy',
							unbranded: '/xywav-idiopathic-hypersomnia',
						},
					},
					innerCardTwo: {
						header: 'Symptoms and Impact',
						bodyRegular:
							'Interested in learning about the symptoms of Idiopathic Hypersomnia? Or how this condition can impact your daily life? Select this option to get answers to these questions and more.',
						url: {
							branded: '/xywav-narcolepsy',
							unbranded: '/idiopathic-hypersomnia',
						},
					}
				}}
			/>
			<Video
				class='container videoContainer'
				id='webinarspreview'
				header={
					<span>
						Want a glimpse <br className='desktopOnly' />
						at what our <br className='desktopOnly' />
						webinars have <br className='desktopOnly' />
						to offer?
					</span>
				}
				copy='Check out this video for a sneak peek!'
				quote='Hearing Dr. Roy helped me feel less alone and more supported. Many thanks to Dr. Roy and Jazz Pharmaceuticals for this very informative webinar.'
				quoteName='Webinar Attendee'
			/>
		</Layout>
	);
};

export default Homepage;
