import React, { useState, useEffect } from 'react';
import GridLayout from '@mui/material/Grid';
import ImageActionCard from '@mui-components/imageActionCard.jsx';
import { SlowReveal } from '../utility/Animations';

let currentCard = null;

const Hero = (props) => {
	const cardOne = 'card one';
	const cardTwo = 'card two';
	const deepLinkNarcolepsy = '#narcolepsy';
	const deepLinkIH = '#idiopathichypersomnia';

	const [getTitle, setTitle] = useState(null);

	useEffect(() => {
		window.addEventListener('scroll', SlowReveal);
		deeplink();
	}, []);

	const deeplink = () => {
		const urlHash = window.location.hash;

		if(urlHash === deepLinkNarcolepsy) {
			cardClicked(cardOne);
		}

		if(urlHash === deepLinkIH) {
			cardClicked(cardTwo);
		}
	}

	const goBackClick = () => {
		let el = document.querySelectorAll('.animate');
		el.forEach((item) => {
			item.classList.remove('move');
		});
		CenterHeader();
	};

	const CenterHeader = () => {
		document.querySelector('.getStartedHeader').scrollIntoView()
	}

	const cardClicked = (item) => {
		enableTab();

		let sections = document.querySelectorAll('.animate');
		currentCard = item;

		if (item === cardOne) {
			setTitle('narcolepsy');
		} else {
			setTitle('ih');
		}

		sections.forEach((section) => {
			section.classList.add('move');
		});

		CenterHeader();


	};

	const enableTab = () => {
		let tabSegment = document.querySelector('.selectTopicBackground').querySelectorAll('[tabindex = "-1"]');

		tabSegment.forEach(item => {
			item.tabIndex = '0';
		});
	}

	const narcolepsyTitle = () => {
		return (
			<p className='getStartedHeaderInner'>
				What about <strong>Narcolepsy</strong> would you like to know?
			</p>
		);
	};

	const ihTitle = () => {
		return (
			<p className='getStartedHeaderInner'>
				What information would you like on <strong>Idiopathic Hypersomnia</strong>
				?
			</p>
		);
	};

	return (
		<>
			<div className='selectTopicBackground'>
				<section className='container selectTopic'>
					<GridLayout container>
						<div className='animate first getStartedCta'>
							<GridLayout
								container
								spacing={2}
								justifyContent={'space-between'}>
								<GridLayout
									item
									xs={12}
									md={10}
									className='getStartedHeader'>
									<p className='getStartedHeaderInner'>
										{props.headerOne}
									</p>
								</GridLayout>
								<GridLayout
									item
									xs={12}
									md={5}
									className='space-under'>
									<ImageActionCard
										onClick={() => cardClicked(cardOne)}
										class='index-imageCard imageCard arrow'
										defaultRipple={false}
										header={props.cardOne.header}
										bodyBold={props.cardOne.bodyBold}
										bodyRegular={props.cardOne.bodyRegular}
									/>
								</GridLayout>
								<GridLayout item xs={12} md={5}>
									<ImageActionCard
										onClick={() => cardClicked(cardTwo)}
										class='index-imageCard imageCard arrow'
										defaultRipple={false}
										header={props.cardTwo.header}
										bodyBold={props.cardTwo.bodyBold}
										bodyRegular={props.cardTwo.bodyRegular}
									/>
								</GridLayout>
							</GridLayout>
						</div>

						<div className='animate second getStartedCta contentSelectorContainer' aria-disabled='true'>
							<GridLayout
								container
								spacing={2}
								justifyContent={'space-between'}>
								<GridLayout
									item
									xs={12}
									md={10}
									className='getStartedHeader'>
									{getTitle === 'narcolepsy'
										? narcolepsyTitle()
										: ihTitle()}
								</GridLayout>
								<GridLayout item xs={12} md={5}>
									<a tabIndex='-1' href={getTitle === 'narcolepsy' ?
											currentCard === cardOne ? props.cardOne.innerCardOne.url.branded : props.cardOne.innerCardOne.url.unbranded
											:  currentCard === cardOne ? props.cardTwo.innerCardOne.url.branded : props.cardTwo.innerCardOne.url.unbranded}
										className='removeUnderline'
										target={getTitle === 'ih' ? '_blank' : '_self'}>
										<ImageActionCard
											class='index-imageCard imageCard arrow'
											onClick={() => null}
											defaultRipple={false}
											tabIndex='-1'
											header={
												getTitle === 'narcolepsy'
													? props.cardOne.innerCardOne.header
													: props.cardTwo.innerCardOne.header
											}
											bodyRegular={
												getTitle === 'narcolepsy'
													? props.cardOne.innerCardOne
															.bodyRegular
													: props.cardTwo.innerCardOne
															.bodyRegular
											}
										/>
									</a>
								</GridLayout>
								<GridLayout item xs={12} md={5}>
									<a tabIndex='-1' href={getTitle === 'narcolepsy' ?
											currentCard === cardOne ? props.cardOne.innerCardTwo.url.branded : props.cardOne.innerCardTwo.url.unbranded
											:  currentCard === cardOne ? props.cardTwo.innerCardTwo.url.branded : props.cardTwo.innerCardTwo.url.unbranded}
										className='removeUnderline'
										target={getTitle === 'narcolepsy' ? '_blank' : '_self'}>
										<ImageActionCard
											class='index-imageCard imageCard arrow'
											defaultRipple={false}
											tabIndex='-1'
											header={
												getTitle === 'narcolepsy'
													? props.cardOne.innerCardTwo.header
													: props.cardTwo.innerCardTwo.header
											}
											bodyRegular={
												getTitle === 'narcolepsy'
													? props.cardOne.innerCardTwo
															.bodyRegular
													: props.cardTwo.innerCardTwo
															.bodyRegular
											}
										/>
									</a>
								</GridLayout>
								<GridLayout
									item
									xs={12}
									md={10}
									className='linkContainer'>
									<span>&#10094;</span>
									<button tabIndex='-1' className='goBackLink' onClick={goBackClick}>
										Return to Select a Condition
									</button>
								</GridLayout>
							</GridLayout>
						</div>
					</GridLayout>
				</section>
			</div>
		</>
	);
};

export default Hero;
