import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { CardActionArea } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';

/* USAGE
<ImageActionCard
    onClick="() => function()"
	className="imageCard"
    defaultRipple={false}
	imageObj={{
		image: "/images/imag.jpg",
		height: "140px",
		alt: "alt desc goes here"
	}}
	content={{
		header: "heading",
		bodyBold: "Bold body content",
        bodyRegular: "regular body content"
	}}
/>
*/

const theme = (disableRipple) => createTheme({
    components: {
      // Name of the component
        MuiButtonBase: {
            defaultProps: {
            // The props to change the default for.
            disableRipple: !disableRipple, // No more ripple!
            },
        },
    },
});

const ImageActionCard = (props) => {
    let attributes = props;

    return (
        <ThemeProvider theme={theme(attributes.defaultRipple)}>
            <Card className={attributes.class} onClick={attributes.onClick}>
                <CardActionArea tabIndex={attributes.tabIndex || 0}>
                    <CardContent>
                        <h5 className="header">{attributes.header}</h5>
                        <p className="body bold">{attributes.bodyBold} <span className="regularWeight">{attributes.bodyRegular}</span></p>
                    </CardContent>
                </CardActionArea>
            </Card>
        </ThemeProvider>
    );
}

export default ImageActionCard;