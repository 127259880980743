import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { useLocation } from '@reach/router';
import { useStaticQuery, graphql } from 'gatsby';

const Seo = ({ title, description }) => {
	const { pathname } = useLocation();
	const { site } = useStaticQuery(query);

	const { defaultTitle, titleTemplate, defaultDescription, siteUrl, defaultImage } =
		site.siteMetadata;

	const seo = {
		title: title || defaultTitle,
		description: description || defaultDescription,
		image: defaultImage,
		url: `${siteUrl}${pathname}`,
	};

	return (
		<Helmet htmlAttributes={{lang: 'en'}} title={seo.title} titleTemplate={titleTemplate}>
			{seo.description && <meta name='description' content={seo.description} />}
			<meta name='image' content={seo.image} />
			{seo.url && <meta property='og:url' content={seo.url} />}
			<meta property='og:type' content='website' />
			{seo.title && <meta property='og:title' content={seo.title} />}
			{seo.description && (
				<meta property='og:description' content={seo.description} />
			)}
			<meta property='og:image' content={seo.image} />
			<link rel='preconnect' href='https://www.google-analytics.com' />
			<link rel='dns-prefetch' href='https://www.googletagmanager.com' />
			<link rel='preconnect' href='https://www.google-analytics.com' />
			<link rel='dns-prefetch' href='https://www.googletagmanager.com' />
			<link rel="canonical" href={'https://www.jazzwebinars.com'+pathname} />
		</Helmet>
	);
};

export default Seo;

const query = graphql`
	query SeoMetadata {
		site {
			siteMetadata {
				defaultTitle: title
				titleTemplate
				defaultDescription: description
				siteUrl: url
				defaultImage: image
			}
		}
	}
`;

Seo.propTypes = {
	title: PropTypes.string.isRequired,
	description: PropTypes.string.isRequired,
};

Seo.defaultProps = {
	title: null,
	description: null,
};
